import { encode, ParsedUrlQueryInput } from 'querystring';
import api from './index';
import {
  INutritionist,
  INutritionistPaginate,
} from '../types/NutritionistInterface';
import { ShopifyPriceRule } from '../types/ShopifyInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getNutritionists = async (
  page: number,
  limit: number,
  searchText: string,
  token: string,
  startDate: string,
  endDate: string
) => {
  const response = await api.get<INutritionistPaginate>(
    `/nutritionist?${encode({
      limit,
      page,
      searchText,
      startDate,
      endDate,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getNutritionist = async (id: string, token: string) => {
  const response = await api.get<INutritionist>(`/nutritionist/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const createNutritionist = async (
  data: Partial<INutritionist>,
  token: string
) => {
  const response = await api.post<INutritionist>(`/nutritionist`, data, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 201) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateNutritionist = async (
  id: string,
  data: Partial<INutritionist>,
  token: string
) => {
  const response = await api.put<INutritionist>(`/nutritionist/${id}`, data, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const deleteNutritionist = async (id: string, token: string) => {
  const response = await api.delete<INutritionist>(`/nutritionist/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const createNutritionistDiscountCode = async (
  id: string,
  discountPercentage: number,
  token: string
) => {
  const response = await api.post<{
    discountCodeTitle: string;
    discountPercentage: number;
    priceRule: ShopifyPriceRule;
  }>(
    `/nutritionist/${id}/discount`,
    { discountPercentage },
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export default {
  getNutritionists,
  getNutritionist,
  createNutritionist,
  updateNutritionist,
  deleteNutritionist,
};
