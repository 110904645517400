import React from 'react';
import format from 'date-fns/format';
import { ShopifyOrder } from '../../types/ShopifyInterface';
import { convertStringToMoneyFormat } from '../../utils/helpers';

interface OrderDetailProps {
  order: ShopifyOrder;
}

const OrderDetailComponent = ({
  order: {
    id,
    name,
    customer,
    created_at,
    financial_status,
    total_price,
    source_name,
    line_items,
  },
}: OrderDetailProps) => {
  return (
    <>
      <section className="order-detail">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-row row--left">
              <p>
                <strong>Pedido:</strong>
              </p>
              <p className="gray-text">{`${name || ''}`}</p>
            </div>
            <div className="form-row row--left">
              <p>
                <strong>Fecha:</strong>
              </p>
              <p className="gray-text">{`${format(
                new Date(created_at || ''),
                'dd/MM/yy'
              )}`}</p>
            </div>
            <div className="form-row row--left">
              <p>
                <strong>Cliente:</strong>
              </p>
              <p className="gray-text">{`${customer.first_name} ${customer.last_name}`}</p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-row row--left">
              <p>
                <strong>Estatus:</strong>
              </p>
              <span
                className="p-tag p-component p-tag-success"
                data-pc-name="tag"
                data-pc-section="root"
              >
                <span className="p-tag-value" data-pc-section="value">
                  {`${financial_status}`}
                </span>
                <span></span>
              </span>
            </div>
            <div className="form-row row--left">
              <p>
                <strong>Canal:</strong>
              </p>
              <p className="gray-text">{`${source_name}`}</p>
            </div>
            <div className="form-row row--left">
              <p>
                <strong>TOTAL:</strong>
              </p>
              <p className="gray-text">
                <strong>{`${convertStringToMoneyFormat(total_price)}`}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form-row row--left">
            <p>
              <strong>
                Productos:{' '}
                <span className="orange-text">{`${
                  (line_items || []).length
                }`}</span>
              </strong>
            </p>
            <div className="form-row mb-0">
              <ul>
                {(line_items || []).map((item, index) => (
                  <li key={index}>
                    <p className="gray-text">{`${item.title} x ${item.quantity}`}</p>
                    <p className="orange-text">
                      <strong>
                        <small>{`${item.sku}`}</small>
                      </strong>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <p>
            Más información en{' '}
            <a
              href={`https://admin.shopify.com/store/innata-mx/orders/${id}`}
              target="_blank"
              rel="noreferrer"
            >
              Shopify
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default OrderDetailComponent;
