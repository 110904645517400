import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import Swal from 'sweetalert2';
import { Tag } from 'primereact/tag';
import HeaderComponent from '../../components/Shared/Header/header.component';
import NewInsideUserComponent from '../../components/NewInsideUser/newInsideUser.component';
import { getSession } from '../../utils/helpers';
import {
  getAdmins,
  newAdminInvitations,
  signUp,
  updateAdmin,
} from '../../api/adminsApi';

interface Product {
  dbId: string;
  id: string;
  name: string;
  email: string;
  phone: string;
  status: string;
  delete: string;
}

const UserInsideScreen = () => {
  const [session, setSession] = useState(getSession());
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    phone: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });

  const getSeverity = (status: any) => {
    switch (status) {
      case 'Activo':
        return 'info';

      case 'Inactivo':
        return 'warning';
    }
  };

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    const session = getSession();
    if (!session || !session.sessionToken) {
      window.location.href = '/';
    }
    setSession(session);
    getAdmins(1, 10, '', session?.sessionToken || '')
      .then((response) => {
        const _products: Product[] = [];
        response.docs.forEach((item) => {
          _products.push({
            dbId: `${item._id}`,
            id: `${item.adminId}`,
            name: item.username,
            email: item.email,
            phone: item.phone,
            status: item.isActive ? 'Activo' : 'Inactivo',
            delete: `${item._id}`,
          });
        });
        setProducts(_products);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const renderHeader = () => {
    return (
      <div className="col-md-4 col-12 col-reset">
        <div className="search-bar">
          <span className="material-icons-outlined icon">search</span>
          <input
            type="search"
            className="form-control"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar..."
          />
        </div>
      </div>
    );
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    const _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = renderHeader();
  const statusBody = (rowData: any) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };
  const specialistBody = (rowData: Product, options: ColumnBodyOptions) => {
    console.log('props', options);
    return (
      <>
        {rowData.status === 'Activo' ? (
          <Link
            to="#"
            className="red-text"
            onClick={(e) => {
              e.preventDefault();
              Swal.fire({
                title: `¿Seguro que deseas desactivar al usuario ${rowData.name}?`,
                text: 'Esta acción puede ser revertida',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sí, desactivar!',
              }).then((result) => {
                if (result.isConfirmed) {
                  updateAdmin(
                    rowData.dbId,
                    { isActive: false },
                    session?.sessionToken || ''
                  )
                    .then(() => {
                      rowData.status = 'Inactivo';
                      products[options.rowIndex] = rowData;
                      setProducts([...products]);
                      Swal.fire(
                        'Eliminado!',
                        'El usuario ha sido desactivado.',
                        'success'
                      );
                    })
                    .catch((error) => {
                      setError(error.message);
                      setLoading(false);
                    });
                }
              });
            }}
          >
            <strong>Desactivar</strong>
          </Link>
        ) : (
          <Link
            to="#"
            className="green-text"
            onClick={(e) => {
              e.preventDefault();
              Swal.fire({
                title: `¿Seguro que deseas activar al usuario ${rowData.name}?`,
                text: 'Esta acción puede ser revertida',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sí, activar!',
              }).then((result) => {
                if (result.isConfirmed) {
                  updateAdmin(
                    rowData.dbId,
                    { isActive: true },
                    session?.sessionToken || ''
                  )
                    .then(() => {
                      rowData.status = 'Activo';
                      products[options.rowIndex] = rowData;
                      setProducts([...products]);
                      Swal.fire(
                        'Activado!',
                        'El usuario ha sido activado.',
                        'success'
                      );
                    })
                    .catch((error) => {
                      setError(error.message);
                      setLoading(false);
                    });
                }
              });
            }}
          >
            <strong>Activar</strong>
          </Link>
        )}
      </>
    );
  };

  const handleUserCreated = async (user: any) => {
    const randomPassword = Math.random().toString(36).slice(-8);
    const invitation = await newAdminInvitations(session?.sessionToken || '');
    signUp({ ...user, password: randomPassword, invCode: invitation.invCode })
      .then((response) => {
        const _products = [...products];
        _products.push({
          dbId: `${response._id}`,
          id: `${response.adminId}`,
          name: response.username,
          email: response.email,
          phone: response.phone,
          status: response.isActive ? 'Activo' : 'Inactivo',
          delete: `${response._id}`,
        });
        setProducts(_products);
        setModalShow(false); // Cierra el modal después de la creación del usuario
        Swal.fire({
          icon: 'success',
          title: 'Usuario creado',
          html: `<p>
          Se ha creado el usuario <strong>${response.username}</strong>,
          con la siguiente contraseña: <strong>${randomPassword}</strong>
          Guarda esta contraseña en un lugar seguro. Esta contraseña no se volverá a mostrar.
        </p>`,
          showConfirmButton: true,
        });
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  return (
    <>
      <HeaderComponent />
      <NewInsideUserComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onUserCreated={handleUserCreated}
      />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashboard-head">
                  <button
                    type="button"
                    onClick={() => setModalShow(true)}
                    className="btn btn--type1"
                  >
                    Añadir usuario
                  </button>
                </div>
                <section className="card-layout">
                  <h1 className="mb-3 h3">Usuarios</h1>
                  <DataTable
                    value={products}
                    tableStyle={{ minWidth: '50rem' }}
                    paginator
                    rows={20}
                    loading={loading}
                    header={header}
                    filters={filters}
                    size={'small'}
                    stripedRows
                    scrollable
                    scrollHeight="400px"
                  >
                    <Column field="id" header="ID" sortable></Column>
                    <Column
                      field="name"
                      header="Nombre de usuario"
                      sortable
                    ></Column>
                    <Column
                      field="email"
                      header="Correo electrónico"
                      sortable
                    ></Column>
                    <Column field="phone" header="Teléfono" sortable></Column>
                    <Column
                      field="status"
                      header="Actividad"
                      sortable
                      body={statusBody}
                    ></Column>
                    <Column
                      field="delete"
                      header="Detalle"
                      body={specialistBody}
                    ></Column>
                  </DataTable>
                </section>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default UserInsideScreen;
