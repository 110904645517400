import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { deleteSession } from '../../../utils/helpers';

const HeaderComponent = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const isLinkActive = (path: any) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="header__col col-12">
              <Link to="/dashboard" className="header__logo">
                <figure>
                  <img src="/assets/images/logo/innata.png" alt="Innata" />
                </figure>
              </Link>
              <nav className="main-nav" data-active={isActive ? 'active' : ''}>
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      className={isLinkActive('/dashboard')}
                    >
                      Inicio
                    </Link>
                  </li>
                  <li>
                    <Link to="/sales" className={isLinkActive('/sales')}>
                      Pedidos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-outside"
                      className={isLinkActive('/user-outside')}
                    >
                      Nutriólogos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-inside"
                      className={isLinkActive('/user-inside')}
                    >
                      Usuarios
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile" className={isLinkActive('/profile')}>
                      Mi perfil
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={(event) => {
                        event.preventDefault();
                        deleteSession();
                        setTimeout(() => {
                          window.location.href = '/';
                        }, 100);
                      }}
                    >
                      Cerrar sesión{' '}
                      <span className="material-icons-outlined icon">
                        logout
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
              <button
                type="button"
                id="open-nav"
                className={isActive ? 'active' : ''}
                onClick={handleClick}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderComponent;
