import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { renderToString } from 'react-dom/server';
import HeaderComponent from '../../../components/Shared/Header/header.component';
import OrderDetailComponent from '../../../components/OrderDetail/orderDetail.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { convertStringToMoneyFormat, getSession } from '../../../utils/helpers';
import {
  deleteNutritionist,
  getNutritionist,
  updateNutritionist,
} from '../../../api/nutritionistsApi';
import { INutritionist } from '../../../types/NutritionistInterface';
import { getNutritionistsSales } from '../../../api/nutritionistSalesApi';
import { ShopifyOrder } from '../../../types/ShopifyInterface';

interface Product {
  id: string;
  date: Date;
  customer: string;
  channel: string;
  total: string;
  status: string;
  quantity: number;
  orderUrl: string;
  totalNumber: number;
  totalDiscounts: number;
  discountCode: string;
}

interface FormData {
  discount: boolean;
  discountPercentage: string;
}

const UserOutsideDetailScreen = () => {
  const { id } = useParams();
  const qrCodeRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [nutritionist, setNutritionist] = useState<Partial<INutritionist>>({});
  const [orders, setOrders] = useState<ShopifyOrder[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [qrCodeGenerated, setQrCodeGenerated] = useState(false);
  const [nutritionistLink, setNutritionistLink] = useState<string>('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    customer: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    channel: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    total: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    status: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    quantity: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  const [sessionToken, setSessionToken] = useState<string>('');
  const [dateSelected, setDateSelected] = useState<string>('');
  const [formData, setFormData] = useState<FormData>({
    discount: false,
    discountPercentage: '',
  });

  const navigate = useNavigate();

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getStartAndEndDate = (month: number, year: number) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    return {
      startDate,
      endDate: new Date(endDate.setDate(endDate.getDate() + 1)),
    };
  };

  const fetchSales = async (
    tags: string,
    token: string,
    startDate: Date,
    endDate: Date
  ) => {
    try {
      const data = await getNutritionistsSales(
        '',
        250,
        tags,
        token,
        startDate.toISOString(),
        endDate.toISOString()
      );
      setOrders(data.orders);
      const products = data.orders.map((item, index) => {
        return {
          id: `${item.name}`,
          date: new Date(item.created_at),
          customer: `${item.customer.first_name} ${item.customer.last_name}`,
          channel: item.source_name,
          total: convertStringToMoneyFormat(item.total_price),
          status: item.financial_status,
          quantity: item.line_items.length,
          orderUrl: '',
          orderIndex: index,
          totalNumber: parseFloat(item.total_price),
          totalDiscounts: parseFloat(item.total_discounts || '0') || 0,
          discountCode: item.discount_codes[0]?.code || '',
        };
      });
      setProducts(products);
      setLoading(false);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const session = getSession();
    if (!session) {
      window.location.href = '/';
    }
    if (!id) {
      window.location.href = '/user-outside';
    }
    setSessionToken(session?.sessionToken || '');
    setLoading(true);
    getNutritionist(`${id}`, session?.sessionToken || '')
      .then((data) => {
        setNutritionist(data);
        const nutritionistLink = `${data.username
          .normalize('NFD')
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
          .normalize()
          .trim()
          .toLowerCase()
          .replace(/á/g, 'a')
          .replace(/\s/g, '-')}-${data.nutritionistId}`;
        setNutritionistLink(nutritionistLink);
        generateQRCode(nutritionistLink);
        const { startDate, endDate } = getStartAndEndDate(
          new Date().getMonth(),
          new Date().getFullYear()
        );
        setDateSelected(formatMonthYear(startDate));
        fetchSales(
          `nutritionistId:${id}`,
          session?.sessionToken || '',
          startDate,
          endDate
        );
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const getSeverity = (status: any) => {
    switch (status) {
      case 'Paid':
        return 'success';

      case 'Pending':
        return 'warning';
    }
  };

  const renderHeader = () => {
    return (
      <div className="col-md-4 col-12 col-reset">
        <div className="search-bar">
          <span className="material-icons-outlined icon">search</span>
          <input
            type="search"
            className="form-control"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar..."
          />
        </div>
      </div>
    );
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    const _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = renderHeader();
  const statusBody = (rowData: any) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };

  const specialistBody = (data: { orderIndex: number }) => {
    const handleClick = (event: any, order: ShopifyOrder | null) => {
      event.preventDefault(); // Evita que se abra la URL
      if (!order) {
        console.error('No se encontró el pedido.');
        return;
      }
      const htmlString = renderToString(<OrderDetailComponent order={order} />);
      Swal.fire({
        title: `<h4>Detalle del pedido <strong class="gray-text">${order.name}</h4>`,
        html: htmlString,
        confirmButtonText: 'Aceptar',
      });
    };

    const order = orders.length > 0 ? orders[data.orderIndex] : null;

    return (
      <button
        onClick={(event) => handleClick(event, order)}
        type="button"
        className="btn--simple"
      >
        <strong>Ver detalle</strong>
      </button>
    );
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const formatDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy');
  };

  const dateBodyTemplate = (rowData: any) => {
    return formatDate(rowData.date);
  };

  const downloadQR = () => {
    if (!qrCodeGenerated || !qrCodeUrl) {
      console.error('El código QR aún no se ha generado.');
      return;
    }

    if (qrCodeRef.current) {
      htmlToImage
        .toPng(qrCodeRef.current, {
          width: 700,
          height: 700,
        })
        .then((dataUrl: string) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = dataUrl;
          downloadLink.download = 'qr-code.png';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          Swal.fire({
            icon: 'success',
            title: 'QR Code descargado con éxito',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error: Error) =>
          console.error('Error al descargar el código QR:', error)
        );
    } else {
      console.error('No se puede encontrar el nodo del código QR.');
    }
  };

  const copyToClipboard = (text: string) => {
    const inputElement = document.createElement('input');
    inputElement.setAttribute('value', text);
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);

    // Opcionalmente, puedes mostrar una notificación o mensaje de éxito
    Swal.fire({
      icon: 'success',
      title: 'URL copiada al portapapeles',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const generateQRCode = (nutritionist: string) => {
    // Generar la URL del código QR y marcarlo como generado
    setQrCodeUrl(`https://www.innata.mx/?reference=${nutritionist}`);
    setQrCodeGenerated(true);
  };

  const handleDiscountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      discount: event.target.value === 'si',
    });
    setNutritionist({
      ...nutritionist,
      hasDiscountCode: event.target.value === 'si',
    });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFormData({
      ...formData,
      discountPercentage: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const requiredFields: (keyof INutritionist)[] = [
      'username',
      'email',
      'phone',
    ];
    const valid = requiredFields.every((field) => !!nutritionist[field]);
    if (!valid) {
      setError('Favor de llenar todos los campos.');
      return;
    }
    const session = getSession();
    updateNutritionist(
      nutritionist._id || '',
      { ...nutritionist },
      session?.sessionToken || ''
    )
      .then(() => {
        Swal.fire('¡Guardado!', 'El usuario ha sido guardado.', 'success');
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleDelete = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: `
      El usuario será eliminado, pero su información permanecerá en la base de datos del admin y de Shopify,
      los descuentos ingresados con el código de descuento relacionados no podrán ingresarse en la tienda.
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteNutritionist(
          nutritionist._id || '',
          sessionToken || ''
        );
        if (result) {
          Swal.fire(
            '¡Eliminado!',
            'El usuario ha sido eliminado.',
            'success'
          ).then(() => {
            navigate(-1);
          });
        }
      }
    });
  };

  const formatMonthYear = (date: Date) => {
    if (!date || isNaN(date.getTime())) {
      // Comprueba que la fecha es válida
      return 'Fecha no válida';
    }
    return format(date, 'yyyy-MM');
  };

  // render a list of buttons for the last 3 months with format yyyy-MM
  const renderMonthButtons = () => {
    const months = [];
    for (let i = 0; i < 4; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      months.push(formatMonthYear(date));
    }
    return months.map((month) => (
      <button
        key={month}
        className={`btn btn-sm ${
          dateSelected === month ? 'btn-primary' : 'btn-outline-primary'
        }`}
        onClick={() => {
          const [year, m] = month.split('-');
          const { startDate, endDate } = getStartAndEndDate(
            parseInt(m, 10) - 1,
            parseInt(year, 10)
          );
          fetchSales(`nutritionistId:${id}`, sessionToken, startDate, endDate);
          setDateSelected(month);
        }}
      >
        {month}
      </button>
    ));
  };

  return (
    <>
      <HeaderComponent />
      <BreadcrumbComponent />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="dashboard__col col-md-9 col-12">
                <section className="card-layout">
                  <h1 className="mb-4 h4">
                    Detalle de nutriólogo{' '}
                    <strong>{`${nutritionist.username || ''}`}</strong>
                  </h1>
                  <div className="mb-3 d-flex justify-content-between">
                    <div className="btn-group dashboard-data-button">
                      {renderMonthButtons()}
                    </div>
                  </div>
                  <div className="dashboard-data-extra row">
                    <div className="col-md-4 col-12">
                      <h2 className="mb-0 h4">Total de ventas: </h2>
                      <p className="color-green700">
                        <strong>
                          {convertStringToMoneyFormat(
                            products
                              .reduce(
                                (acc, product) => acc + product.totalNumber,
                                0
                              )
                              .toString()
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="col-md-4 col-12">
                      <h2 className="mb-0 h4">Total de artículos: </h2>
                      <p className="color-green700">
                        <strong>
                          {products.reduce(
                            (acc, product) => acc + product.quantity,
                            0
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="col-md-4 col-12">
                      <h2 className="mb-0 h4">Total de pedidos: </h2>
                      <p className="color-green700">
                        <strong>{products.length}</strong>
                      </p>
                    </div>
                  </div>
                  <DataTable
                    value={products}
                    tableStyle={{ minWidth: '50rem' }}
                    paginator
                    rows={50}
                    loading={loading}
                    header={header}
                    filters={filters}
                    size={'small'}
                    stripedRows
                    scrollable
                    scrollHeight="400px"
                  >
                    <Column field="id" header="Pedido" sortable></Column>
                    <Column
                      field="date"
                      header="Fecha"
                      filterField="date"
                      dataType="date"
                      body={dateBodyTemplate}
                      filter
                      filterElement={dateFilterTemplate}
                      onFilterApplyClick={(event) => {
                        console.log('onFilterApplyClick', event);
                      }}
                    ></Column>
                    <Column field="customer" header="Cliente" sortable></Column>
                    <Column
                      field="totalDiscounts"
                      header="Descuentos"
                      sortable
                      body={(rowData) =>
                        convertStringToMoneyFormat(
                          rowData.totalDiscounts.toString()
                        )
                      }
                    ></Column>
                    <Column
                      field="discountCode"
                      header="Código de descuento"
                      sortable
                    ></Column>
                    <Column field="total" header="Total" sortable></Column>
                    <Column
                      field="status"
                      header="Estado del pago"
                      sortable
                      body={statusBody}
                    ></Column>
                    <Column
                      field="quantity"
                      header="Artículos"
                      sortable
                    ></Column>
                    <Column
                      field="orderUrl"
                      header="Detalle"
                      body={specialistBody}
                    ></Column>
                  </DataTable>
                </section>
              </div>
              <aside className="dashboard__col col-md-3 col-12">
                <section className="card-layout">
                  <h5>Detalle de usuario</h5>
                  <div className="card-detail">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <span
                          className="p-tag p-component p-tag-info"
                          data-pc-name="tag"
                          data-pc-section="root"
                        >
                          <span className="p-tag-value" data-pc-section="value">
                            Activo
                          </span>
                        </span>
                      </div>
                      <div className="form-row row--left">
                        <label>
                          <span className="red-text">*</span> Estatus
                        </label>
                        <select
                          className="form-select"
                          name="discountPercentage"
                          onChange={(event) => {
                            setNutritionist({
                              ...nutritionist,
                              status: event.target.value,
                            });
                          }}
                        >
                          <option>Seleccionar...</option>
                          {['Activo', 'Inactivo'].map((item, key) => {
                            return (
                              <option
                                key={key}
                                selected={nutritionist?.status === item}
                                value={item}
                              >
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-row">
                        <label>
                          <span className="red-text">*</span> Nombre
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="John Carter"
                          value={nutritionist?.username || ''}
                          onChangeCapture={(event) => {
                            setNutritionist({
                              ...nutritionist,
                              username: (event.target as HTMLInputElement)
                                .value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <label htmlFor="category">
                          <span className="red-text">*</span> Categoría
                        </label>
                        <select
                          id="category"
                          className="form-select"
                          name="category"
                          onChange={(event) => {
                            setNutritionist({
                              ...nutritionist,
                              category: event.target.value,
                            });
                          }}
                        >
                          <option>Seleccionar...</option>
                          {[
                            'BALANCE HORMONAL',
                            'BALANCE HORMONAL / EMBARAZO Y LACTANCIA',
                            'BALANCE HORMONAL / DIGESTIÓN',
                            'DIGESTIÓN',
                            'EMBARAZO Y LACTANCIA',
                            'FERTILIDAD Y EMBARAZO (GINECOLOGA)',
                            'FERTILIDAD',
                            'WELLNESS / FITNESS',
                            'WELLNES / BEBES Y NIÑOS',
                            'WELLNESS',
                          ].map((item, key) => {
                            return (
                              <option
                                key={key}
                                selected={nutritionist?.category === item}
                                value={item}
                              >
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-row">
                        <label htmlFor="instagram">
                          <span className="red-text">*</span> Instagram
                        </label>
                        <input
                          type="text"
                          id="instagram"
                          className="form-control"
                          placeholder="@john.carter"
                          value={nutritionist?.instagram || ''}
                          onChangeCapture={(event) => {
                            setNutritionist({
                              ...nutritionist,
                              instagram: (event.target as HTMLInputElement)
                                .value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <label htmlFor="city">
                          <span className="red-text">*</span> Ciudad
                        </label>
                        <input
                          type="text"
                          id="city"
                          className="form-control"
                          placeholder="Ciudad de México"
                          value={nutritionist?.city || ''}
                          onChangeCapture={(event) => {
                            setNutritionist({
                              ...nutritionist,
                              city: (event.target as HTMLInputElement).value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <label>Correo electrónico</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="john.carter@mail.com"
                          value={nutritionist?.email || ''}
                          onChangeCapture={(event) => {
                            setNutritionist({
                              ...nutritionist,
                              email: (event.target as HTMLInputElement).value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <label>Teléfono</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="55123464789"
                          value={nutritionist?.phone || ''}
                          onChangeCapture={(event) => {
                            setNutritionist({
                              ...nutritionist,
                              phone: (event.target as HTMLInputElement).value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row row--left">
                        <label>¿Añadir código de descuento?</label>
                        <div className="check-double">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="descuento"
                              value="no"
                              id="descuentoNo"
                              onChange={handleDiscountChange}
                              checked={
                                nutritionist?.hasDiscountCode ? false : true
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="descuentoNo"
                            >
                              No
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="descuento"
                              value="si"
                              id="descuentoSi"
                              onChange={handleDiscountChange}
                              checked={
                                nutritionist?.hasDiscountCode ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="descuentoSi"
                            >
                              Sí
                            </label>
                          </div>
                        </div>
                      </div>
                      {formData.discount || nutritionist?.discountCode?.id ? (
                        <div className="form-row row--left">
                          <label>Porcentaje de descuento</label>
                          <select
                            className="form-select"
                            aria-label="Porcentaje de descuento"
                            name="discountPercentage"
                            onChange={handleSelectChange}
                            disabled={!nutritionist.hasDiscountCode}
                          >
                            <option>Seleccionar...</option>
                            {[5, 10, 15].map((item, key) => {
                              const discountCodeValue = Number(
                                nutritionist?.discountCode?.value.replace(
                                  '-',
                                  ''
                                )
                              );
                              return (
                                <option
                                  key={key}
                                  selected={discountCodeValue === item}
                                  value={item}
                                >
                                  {item}% de descuento
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : (
                        ''
                      )}
                      <hr />
                      <div className="form-row">
                        <label>QR</label>
                        <div className="form-qr">
                          {qrCodeGenerated && qrCodeUrl && (
                            <QRCode
                              size={256}
                              style={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: '100%',
                              }}
                              value={qrCodeUrl}
                              viewBox="0 0 100 100"
                              ref={qrCodeRef}
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-row row--last">
                        <button
                          type="button"
                          className="btn btn--type2 btn--100"
                          onClick={downloadQR}
                        >
                          Descargar QR
                        </button>
                      </div>
                      <div className="form-row">
                        <label>URL</label>
                        <div className="mb-3 col-12 col-reset">
                          <button
                            type="button"
                            className="btn--small"
                            onClick={() =>
                              copyToClipboard(
                                document
                                  .getElementById('url')
                                  ?.getAttribute('value') || ''
                              )
                            }
                          >
                            <span className="material-icons-outlined icon">
                              content_copy
                            </span>{' '}
                            Copiar URL
                          </button>
                        </div>
                        <div className="form-url">
                          <input
                            type="hidden"
                            id="url"
                            value={`https://www.innata.mx?reference=${nutritionistLink}`}
                          />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://www.innata.mx/?reference="
                            disabled
                          />
                          <input
                            type="text"
                            className="form-control"
                            value={nutritionistLink}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-row row--last">
                        <button
                          type="submit"
                          className="btn btn--type1 btn--100"
                        >
                          Guardar
                        </button>
                      </div>
                      <div className="form-row row--last">
                        <button
                          type="button"
                          className="btn btn--delete btn--100"
                          onClick={handleDelete}
                        >
                          Eliminar
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
              </aside>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default UserOutsideDetailScreen;
