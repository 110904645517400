import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
import LoginScreen from './pages/Auth/Login/login.screen';
import RecoverScreen from './pages/Auth/Recover/recover.screen';
import SignUpScreen from './pages/Auth/SignUp/signup.screen';
import NewPasswordScreen from './pages/Auth/NewPassword/newPassword.screen';
import DashboardScreen from './pages/Dashboard/dashboard.screen';
import SalesScreen from './pages/Sales/sales.screen';
import { PrimeReactProvider } from 'primereact/api';
import UserOutsideScreen from './pages/UserOutside/Index/index.screen';
import UserOutsideDetailScreen from './pages/UserOutside/Detail/detail.screen';
import UserInsideScreen from './pages/UserInside/userInside.screen';
import ProfileScreen from './pages/Profile/profile.screen';
import { getSession } from './utils/helpers';

setDefaultOptions({ locale: es });

function App() {
  const session = getSession();
  return (
    <PrimeReactProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={session ? <DashboardScreen /> : <LoginScreen />}
            />
            <Route path="/recover-password" element={<RecoverScreen />} />
            <Route path="/create-account" element={<SignUpScreen />} />
            <Route path="/new-password" element={<NewPasswordScreen />} />
            <Route
              path="/dashboard"
              element={session ? <DashboardScreen /> : <LoginScreen />}
            />
            <Route
              path="/sales"
              element={session ? <SalesScreen /> : <LoginScreen />}
            />
            <Route
              path="/user-outside"
              element={session ? <UserOutsideScreen /> : <LoginScreen />}
            />
            <Route
              path="/user-outside-detail/:id"
              element={session ? <UserOutsideDetailScreen /> : <LoginScreen />}
            />
            <Route
              path="/user-inside"
              element={session ? <UserInsideScreen /> : <LoginScreen />}
            />
            <Route
              path="/profile"
              element={session ? <ProfileScreen /> : <LoginScreen />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </PrimeReactProvider>
  );
}

export default App;
