import axios from 'axios';

export default axios.create({
  baseURL: 'https://api.innata.mx',
  // baseURL: 'https://api-dev.innata.mx',
  // baseURL: 'http://localhost:5002',
  headers: {
    'Content-Type': 'application/json',
  },
});
