import Cookies from 'js-cookie';
import { IAdmin } from '../types/AdminInterface';
import S3 from 'aws-sdk/clients/s3';

const COOKIE_NAME = 'InnataAdminSessionCooke';

export const saveSession = async (user: Partial<IAdmin>) => {
  if (user) {
    Cookies.set(COOKIE_NAME, JSON.stringify({ ...user }));
  }
};

export const getSession = () => {
  const data = Cookies.get(COOKIE_NAME); // sin sesión
  return data ? (JSON.parse(data) as IAdmin) : null;
};

export const updateSession = (user: IAdmin) => {
  Cookies.remove(COOKIE_NAME);
  Cookies.set(COOKIE_NAME, JSON.stringify({ ...user }));
};

export const deleteSession = () => {
  Cookies.remove(COOKIE_NAME);
};

export const fetchImageFromS3 = async (key: string) => {
  const s3 = new S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-2',
  });
  const response = s3.getSignedUrl('getObject', {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME || '',
    Key: `public/${key}`,
  });
  return response;
};

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const convertStringToMoneyFormat = (value: string) => {
  const _value = value.replace('$', '');
  return parseFloat(_value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};
