import { encode, ParsedUrlQueryInput } from 'querystring';
import api from './index';
import { INutritionistSalePaginate } from '../types/NutritionistInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getNutritionistsSales = async (
  page: string,
  limit: number,
  tags: string,
  token: string,
  startDate: string,
  endDate: string
) => {
  const response = await api.get<INutritionistSalePaginate>(
    `/nutritionistSales?${encode({
      limit,
      page,
      tags,
      startDate,
      endDate,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
