import React from 'react';
import { useNavigate } from 'react-router-dom';

const BreadcrumbComponent = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
 
  return (
    <>
     <section className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <button onClick={handleBack} className="btn--simple">
                <span className="material-icons-outlined icon">arrow_back</span> Regresar</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BreadcrumbComponent;
