import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import HeaderComponent from '../../components/Shared/Header/header.component';
import { IAdmin } from '../../types/AdminInterface';
import { getSession } from '../../utils/helpers';
import { signIn, updateAdmin } from '../../api/adminsApi';

const PASSWORD_MIN_LENGTH = 8;

const ProfileScreen = () => {
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setAdmin((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsSubmitDisabled(
      ['username', 'email', 'password'].some(
        (field) => !admin[field as keyof IAdmin]
      )
    );
  };

  const handleTogglePassword = (field: string) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    }
    if (field === 'passwordNew') {
      setShowPasswordNew((prevShowPasswordNew) => !prevShowPasswordNew);
    }
    if (field === 'passwordConfirm') {
      setShowPasswordConfirm(
        (prevShowPasswordConfirm) => !prevShowPasswordConfirm
      );
    }
  };

  const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    const password = (event.target as HTMLTextAreaElement).value || '';
    if (password.length < PASSWORD_MIN_LENGTH) {
      setShowPasswordError(true);
    } else {
      setShowPasswordError(false);
    }

    setPasswordMismatch(password !== admin.passwordConfirm);

    setIsSubmitDisabled(
      ['username', 'email', 'password'].some(
        (field) => !admin[field as keyof IAdmin]
      )
    );

    setAdmin({
      ...admin,
      password: (event.target as HTMLTextAreaElement).value || '',
    });
  };

  const handlePasswordConfirmChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const password = (event.target as HTMLTextAreaElement).value || '';
    setPasswordMismatch(password !== admin.password);
    setIsSubmitDisabled(
      ['username', 'email', 'password'].some(
        (field) => !admin[field as keyof IAdmin]
      )
    );
    setAdmin({
      ...admin,
      passwordConfirm: (event.target as HTMLTextAreaElement).value || '',
    });
  };

  const showConfirmation = () => {
    Swal.fire({
      title: 'Éxito',
      text: 'Perfil actualizado correctamente.',
      icon: 'success',
      confirmButtonText: 'Ok',
    });

    setTimeout(() => {
      navigate('/dashboard');
    }, 1000);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (showChangePassword) {
        if (!passwordMismatch) {
          const login = await signIn({
            email: admin.email || '',
            password: currentPassword,
          });
          if (login) {
            const updatedAdmin = await updateAdmin(
              admin._id || '',
              { ...admin },
              admin.sessionToken || ''
            );
            setAdmin(updatedAdmin);
            showConfirmation();
          } else {
            Swal.fire({
              title: 'Error',
              text: 'La contraseña actual es incorrecta.',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          }
        }
      } else {
        await updateAdmin(
          admin._id || '',
          {
            username: admin.username,
            email: admin.email,
            avatar: admin?.avatar || '',
          },
          admin.sessionToken || ''
        );
        showConfirmation();
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'No se pudo actualizar el perfil.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  useEffect(() => {
    if (!admin.email) {
      const adminResult = getSession();
      if (!adminResult) {
        navigate('/login');
        return;
      }
      setAdmin(adminResult);
    }
  }, []);

  return (
    <>
      <HeaderComponent />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12 mx-auto">
                <section className="card-layout">
                  <h1 className="h3 text-center">Mis datos</h1>
                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <label>Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="John Carter"
                        name="username"
                        value={admin.username}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-row">
                      <label>Correo electrónico</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="tucorreo@mail.com"
                        name="email"
                        value={admin.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-row form--center">
                      <button
                        className="btn btn--type2"
                        onClick={(event) => {
                          event.preventDefault();
                          setShowChangePassword(!showChangePassword);
                        }}
                      >
                        Cambiar contraseña
                      </button>
                    </div>
                    {showChangePassword && (
                      <>
                        <div className="form-row">
                          <label>Contraseña Actual</label>
                          <div className="password-view">
                            <button
                              type="button"
                              className="password-view__button"
                              onClick={() => handleTogglePassword('password')}
                            >
                              <span className="material-icons-outlined">
                                {showPassword ? 'visibility_off' : 'visibility'}
                              </span>
                            </button>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Contraseña"
                              className="form-control"
                              onChangeCapture={(evt) => {
                                setCurrentPassword(
                                  (evt.target as HTMLTextAreaElement).value ||
                                    ''
                                );
                              }}
                              required
                              minLength={PASSWORD_MIN_LENGTH}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <label>Nueva contraseña</label>
                          <div className="password-view">
                            <button
                              type="button"
                              className="password-view__button"
                              onClick={() =>
                                handleTogglePassword('passwordNew')
                              }
                            >
                              <span className="material-icons-outlined">
                                {showPasswordNew
                                  ? 'visibility_off'
                                  : 'visibility'}
                              </span>
                            </button>
                            <input
                              type={showPasswordNew ? 'text' : 'password'}
                              placeholder="Contraseña"
                              className="form-control"
                              onChangeCapture={handlePasswordChange}
                              required
                              minLength={8}
                            />
                          </div>
                          {showPasswordError && (
                            <div className="alert alert-danger" role="alert">
                              La contraseña debe tener al menos{' '}
                              <strong>{PASSWORD_MIN_LENGTH} caracteres</strong>
                            </div>
                          )}
                        </div>
                        <div className="form-row">
                          <label>Confirmar contraseña</label>
                          <div className="password-view">
                            <button
                              type="button"
                              className="password-view__button"
                              onClick={() =>
                                handleTogglePassword('passwordConfirm')
                              }
                            >
                              <span className="material-icons-outlined">
                                {showPasswordConfirm
                                  ? 'visibility_off'
                                  : 'visibility'}
                              </span>
                            </button>
                            <input
                              type={showPasswordConfirm ? 'text' : 'password'}
                              placeholder="Confirmar contraseña"
                              className="form-control"
                              onChange={handlePasswordConfirmChange}
                              required
                              minLength={8}
                            />
                          </div>
                          <div
                            className={`alert alert-danger ${
                              passwordMismatch ? '' : 'hidden'
                            }`}
                            role="alert"
                          >
                            {passwordMismatch
                              ? 'Las contraseñas no coinciden.'
                              : ''}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-row row--center row--last">
                      <button
                        className="btn btn--type1"
                        disabled={isSubmitDisabled || passwordMismatch}
                      >
                        Guardar
                      </button>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default ProfileScreen;
