import React from 'react';

const FooterComponent = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                <small>INNATA © 2024</small>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterComponent;
