import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const RecoverScreen = () => {
  const [email, setEmail] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    setIsSubmitDisabled(value.trim() === '');
    setShowErrorMessage(false); // Ocultar la alerta de error cuando se llena el campo nuevamente
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Verificar el correo electrónico en la base de datos (simulado aquí con setTimeout)
    // Reemplaza esta lógica con una llamada a tu backend para verificar el correo
    setTimeout(() => {
      if (email === 'correoexistente@example.com') {
        setEmail('');
        setIsSubmitDisabled(true); // Deshabilitar el botón de submit después de enviar
        Swal.fire({
          icon: 'success',
          title: 'Correo enviado',
          text: 'Te hemos enviado un correo electrónico con las instrucciones para recuperar tu contraseña.',
          confirmButtonText: 'Aceptar',
        }).then(() => {
          setShowErrorMessage(false); // Ocultar la alerta de error
        });
      } else {
        setShowErrorMessage(true);
      }
    }, 500);
  };


  return (
    <>
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12 mx-auto">
              <div className="auth-wrapp">
                <div className="auth-head">
                  <Link to="/" className="btn--simple"><span className="material-icons-outlined icon">arrow_back</span> Regresar</Link>
                </div>
                <figure className="auth-logo">
                  <img src="/assets/images/logo/innata.png" alt="Innata" />
                </figure> 
                <article className="auth-article text-center">
                  <h1 className="h3">Super Admin</h1>
                  <p>Recuperar contraseña</p>
                  <p className="gray-text">Ingresa tu correo electrónico para poder enviarte las <strong>instrucciones</strong> para recuperar tu contraseña</p>
                </article>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label>Correo electrónico</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="tucorreo@mail.com"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {showErrorMessage && (
                      <div className="alert alert-danger" role="alert">
                        El correo electrónico no se encuentra dentro de nuestros registros.
                      </div>
                    )}
                  </div>
                  <div className="form-row row--center row--last">
                    <button className="btn btn--type1" disabled={isSubmitDisabled}>
                      Iniciar sesión
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
};

export default RecoverScreen;