import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { INutritionist } from '../../types/NutritionistInterface';

interface Props {
  onUserCreated: (nutritionist: Partial<INutritionist>) => Promise<void>;
  onHide: () => void;
  show: boolean;
}

const NewOutsideUserComponent: React.FC<Props> = ({
  onUserCreated,
  onHide,
  show,
}) => {
  const [formData, setFormData] = useState<INutritionist>({
    username: '',
    category: '',
    instagram: '',
    city: '',
    discountCode: {
      value: '',
    },
  });

  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    const { username, category, instagram, city } = formData;
    if (username && category && instagram && city) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [formData]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onUserCreated(formData).then(() => {
      onHide(); // cierra el modal después de mostrar la alerta

      // Reinicia los campos del formulario
      setFormData({
        username: '',
        email: '',
        phone: '',
        discountCode: {
          value: '',
        },
        hasDiscountCode: false,
      });

      // Desactiva el botón de submit
      setButtonDisabled(true);
    });
  };

  const handleDiscountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      hasDiscountCode: event.target.value === 'si',
    });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFormData({
      ...formData,
      discountCode: {
        value: event.target.value,
      },
    });
  };

  return (
    <>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
        show={show}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir nutriólogo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="user-form">
            <form onSubmit={handleFormSubmit}>
              <div className="form-row row--left">
                <label>
                  <span className="red-text">*</span> Nombre
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre completo del nutriólogo"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-row row--left">
                <label htmlFor="category">
                  <span className="red-text">*</span> Categoría
                </label>
                <select
                  id="category"
                  className="form-select"
                  name="category"
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      category: event.target.value,
                    });
                  }}
                >
                  <option>Seleccionar...</option>
                  {[
                    'BALANCE HORMONAL',
                    'BALANCE HORMONAL / EMBARAZO Y LACTANCIA',
                    'BALANCE HORMONAL / DIGESTIÓN',
                    'DIGESTIÓN',
                    'EMBARAZO Y LACTANCIA',
                    'FERTILIDAD Y EMBARAZO (GINECOLOGA)',
                    'FERTILIDAD',
                    'WELLNESS / FITNESS',
                    'WELLNES / BEBES Y NIÑOS',
                    'WELLNESS',
                  ].map((item, key) => {
                    return (
                      <option
                        key={key}
                        selected={formData?.category === item}
                        value={item}
                      >
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-row row--left">
                <label htmlFor="instagram">
                  <span className="red-text">*</span> Instagram
                </label>
                <input
                  type="text"
                  id="instagram"
                  className="form-control"
                  placeholder="@john.carter"
                  value={formData?.instagram || ''}
                  onChangeCapture={(event) => {
                    setFormData({
                      ...formData,
                      instagram: (event.target as HTMLInputElement).value,
                    });
                  }}
                />
              </div>
              <div className="form-row row--left">
                <label htmlFor="city">
                  <span className="red-text">*</span> Ciudad
                </label>
                <input
                  type="text"
                  id="city"
                  className="form-control"
                  placeholder="Ciudad"
                  value={formData?.city || ''}
                  onChangeCapture={(event) => {
                    setFormData({
                      ...formData,
                      city: (event.target as HTMLInputElement).value,
                    });
                  }}
                />
              </div>
              <div className="form-row row--left">
                <label>¿Añadir código de descuento?</label>
                <div className="check-double">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="descuento"
                      value="no"
                      id="descuentoNo"
                      onChange={handleDiscountChange}
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="descuentoNo">
                      No
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="descuento"
                      value="si"
                      id="descuentoSi"
                      onChange={handleDiscountChange}
                    />
                    <label className="form-check-label" htmlFor="descuentoSi">
                      Sí
                    </label>
                  </div>
                </div>
              </div>
              {formData.hasDiscountCode && (
                <div className="form-row row--left">
                  <label>Porcentaje de descuento</label>
                  <select
                    className="form-select"
                    aria-label="Porcentaje de descuento"
                    name="discountPercentage"
                    value={formData.discountCode.value}
                    onChange={handleSelectChange}
                  >
                    <option selected>Seleccionar...</option>
                    <option value="5">5% de descuento</option>
                    <option value="10">10% de descuento</option>
                    <option value="15">15% de descuento</option>
                  </select>
                </div>
              )}
              <div className="form-row row--last row--double">
                <button
                  type="button"
                  className="btn btn--type2"
                  onClick={onHide}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={isButtonDisabled}
                >
                  Crear nutriólogo
                </button>
              </div>
            </form>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewOutsideUserComponent;
