import { encode, ParsedUrlQueryInput } from 'querystring';
import api from './index';
import { IAdmin, IAdminPaginate } from '../types/AdminInterface';
import {
  IAdminInvitation,
  IAdminInvitationPaginate,
} from '../types/AdminInvitationInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const signUp = async (admin: Partial<IAdmin>) => {
  const response = await api.post<IAdmin>('/admin', admin);
  if (response.status !== 201) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const signIn = async (admin: Partial<IAdmin>) => {
  const response = await api.post<IAdmin>('/admin/login', admin);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const forgotPass = async (email: string) => {
  const response = await api.post<IAdmin>('/admin/forgot', { email });
  return response;
};

export const recoverPass = async (admin: Partial<IAdmin>, id: string) => {
  const response = await api.post<IAdmin>(`/admin/recover/${id}`, admin);
  return response;
};

export const getAdmins = async (
  page: number,
  limit: number,
  searchText: string,
  token: string
) => {
  const response = await api.get<IAdminPaginate>(
    `/admin?${encode({
      limit,
      page,
      searchText,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getAdmin = async (adminId: string, token: string) => {
  const response = await api.get<IAdmin>(`/admin/${adminId}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateAdmin = async (
  adminId: string,
  admin: Partial<IAdmin>,
  token: string
) => {
  console.log('updateAdmin', { adminId, admin, token });
  const response = await api.put<IAdmin>(`/admin/${adminId}`, admin, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getAdminInvitations = async (
  offset: number,
  limit: number,
  token: string
) => {
  const response = await api.get<IAdminInvitationPaginate>(
    `/admin/invitation?${encode({
      limit,
      offset,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const newAdminInvitations = async (token: string) => {
  const response = await api.post<IAdminInvitation>(
    '/admin/invitation',
    {},
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
