import React from 'react';
import HeaderComponent from '../../components/Shared/Header/header.component';

const DashboardScreen = () => {
  return (
    <>
      <HeaderComponent />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12 mx-auto">
                <iframe
                  src="https://chartbrew-client.innata.mx/chart/a25e4b8a-afde-45c4-901b-5971e55a392a/embedded"
                  width="1320"
                  height="600"
                  style={{ backgroundColor: '#f5f2ea' }}
                ></iframe>
              </div>
              <div className="col-md-12 col-12 mx-auto">
                <iframe
                  src="https://chartbrew-client.innata.mx/chart/a3bfc144-594c-4652-979a-b80ed56665c7/embedded"
                  width="1320"
                  height="600"
                  style={{ backgroundColor: '#f5f2ea' }}
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default DashboardScreen;
