import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderComponent from '../../../components/Shared/Header/header.component';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import NewOutsideUserComponent from '../../../components/NewOutsideUser/newOutsideUser.component';
import { Tag } from 'primereact/tag';
import { convertStringToMoneyFormat, getSession } from '../../../utils/helpers';
import {
  createNutritionist,
  createNutritionistDiscountCode,
  getNutritionists,
} from '../../../api/nutritionistsApi';
import { INutritionist } from '../../../types/NutritionistInterface';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

interface Product {
  id: string;
  name: string;
  totalSales: string;
  totalProducts: number;
  specialistUrl: string;
  status: string;
  hasDiscountCode: boolean;
}

const ITEMS_PER_PAGE = 20;

const UserOutsideScreen = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [pagination, setPagination] = useState({
    first: 0,
    rows: ITEMS_PER_PAGE,
    page: 1,
    pageCount: 0,
    totalDocs: 0,
  });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    category: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    totalSales: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    totalProducts: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    specialistUrl: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  const [dateSelected, setDateSelected] = useState<string>('');

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getSeverity = (status: any) => {
    switch (status) {
      case 'Activo':
        return 'info';

      case 'Inactivo':
        return 'warning';

      case 'Eliminado':
        return 'danger';
    }
  };

  const fetchNutritionists = async (
    page: number,
    rows: number,
    search: string,
    token: string,
    startDate: Date,
    endDate: Date
  ) => {
    setLoading(true);
    const response = await getNutritionists(
      page,
      rows,
      search,
      token,
      startDate.toISOString(),
      endDate.toISOString()
    );
    const _products = response.docs.map((nutritionist) => {
      return {
        id: `${nutritionist.nutritionistId}`,
        name: nutritionist.username,
        category: nutritionist.category || 'Sin categoría',
        totalSales: convertStringToMoneyFormat(
          nutritionist.totalSales?.toString() || '0'
        ),
        totalProducts: nutritionist.totalProducts || 0,
        specialistUrl: `/user-outside-detail/${nutritionist.nutritionistId}`,
        status: nutritionist.status || 'Activo',
        hasDiscountCode: nutritionist.discountCode.id ? true : false,
      };
    });
    setProducts(_products);
    setLoading(false);
    setPagination({
      ...pagination,
      page: response.page,
      pageCount: Math.ceil(response.totalDocs / ITEMS_PER_PAGE),
      totalDocs: response.totalDocs,
    });
  };

  useEffect(() => {
    const session = getSession();
    if (!session || !session?.sessionToken) {
      window.location.href = '/';
    }
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const { startDate, endDate } = getStartAndEndDate(
      currentMonth,
      currentYear
    );
    setDateSelected(formatMonthYear(startDate));
    fetchNutritionists(
      pagination.page,
      pagination.rows,
      '',
      session?.sessionToken || '',
      startDate,
      endDate
    );
  }, []);

  const renderHeader = () => {
    return (
      <div className="col-md-4 col-12 col-reset">
        <div className="search-bar">
          <span className="material-icons-outlined icon">search</span>
          <input
            type="search"
            className="form-control"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar..."
          />
        </div>
      </div>
    );
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    const _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = renderHeader();
  const statusBody = (rowData: any) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };

  const specialistBody = (rowData: any) => {
    return (
      <Link to={rowData.specialistUrl}>
        <strong>Ver detalle</strong>
      </Link>
    );
  };

  const showConfirmation = (nutritionist: Partial<INutritionist>) => {
    Swal.fire({
      icon: 'success',
      title: 'Nutriólogo creado',
      text: `Se ha creado el nutriólogo ${nutritionist.username}`,
      showConfirmButton: false,
      timer: 2000,
    }).then(() => {
      setLoading(false);
      setModalShow(false);
    });
  };

  const handleUserCreated = (nutritionist: Partial<INutritionist>) => {
    const session = getSession();
    if (!session || !session?.sessionToken) {
      window.location.href = '/';
    }
    setLoading(true);
    return createNutritionist(nutritionist, session?.sessionToken || '')
      .then((response) => {
        if (nutritionist.hasDiscountCode) {
          return createNutritionistDiscountCode(
            response._id || '',
            Number(nutritionist.discountCode?.value),
            session?.sessionToken || ''
          )
            .then(() => {
              showConfirmation(response);
            })
            .catch((error) => {
              setError(error.message);
            });
        }
        return showConfirmation(response);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const onPageChange = (event: any) => {
    setLoading(true);
    setPagination({
      ...pagination,
      page: event.page + 1,
      first: event.first,
      rows: event.rows,
    });
    const { startDate, endDate } = getStartAndEndDate(
      new Date(dateSelected).getMonth() + 1,
      new Date(dateSelected).getFullYear()
    );
    fetchNutritionists(
      event.page + 1,
      event.rows,
      globalFilterValue,
      getSession()?.sessionToken || '',
      startDate,
      endDate
    );
  };

  const getStartAndEndDate = (month: number, year: number) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    return { startDate, endDate };
  };

  const formatMonthYear = (date: Date) => {
    if (!date || isNaN(date.getTime())) {
      // Comprueba que la fecha es válida
      return 'Fecha no válida';
    }
    return format(date, 'yyyy-MM');
  };

  const renderMonthButtons = () => {
    const months = [];
    for (let i = 0; i < 4; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      months.push(formatMonthYear(date));
    }
    return months.map((month) => (
      <button
        key={month}
        className={`btn btn-sm ${
          dateSelected === month ? 'btn-primary' : 'btn-outline-primary'
        }`}
        onClick={() => {
          const [year, m] = month.split('-');
          const { startDate, endDate } = getStartAndEndDate(
            parseInt(m, 10) - 1,
            parseInt(year, 10)
          );
          fetchNutritionists(
            pagination.page,
            pagination.rows,
            '',
            getSession()?.sessionToken || '',
            startDate,
            endDate
          );
          setDateSelected(month);
        }}
      >
        {month}
      </button>
    ));
  };

  return (
    <>
      <HeaderComponent />
      <NewOutsideUserComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onUserCreated={handleUserCreated}
      />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashboard-head">
                  <button
                    type="button"
                    onClick={() => setModalShow(true)}
                    className="btn btn--type1"
                  >
                    Añadir nutriólogo
                  </button>
                </div>
                <section className="card-layout">
                  <h1 className="h3 mb-3">Nutriólogos</h1>
                  <div className="d-flex justify-content-between mb-3">
                    <div className="btn-group dashboard-data-button">
                      {renderMonthButtons()}
                    </div>
                  </div>
                  {!loading && products.length && (
                    <>
                      <DataTable
                        value={products}
                        tableStyle={{ minWidth: '50rem' }}
                        loading={loading}
                        header={header}
                        filters={filters}
                        globalFilterFields={['name']}
                        size={'small'}
                        stripedRows
                        scrollable
                        scrollHeight="400px"
                      >
                        <Column field="id" header="ID" sortable></Column>
                        <Column
                          field="name"
                          header="Nutriólogo"
                          sortable
                        ></Column>
                        <Column
                          field="category"
                          header="Categoría"
                          sortable
                        ></Column>
                        <Column
                          field="totalSales"
                          header="Ventas totales"
                          sortable
                        ></Column>
                        <Column
                          field="totalProducts"
                          header="Productos vendidos"
                          sortable
                        ></Column>
                        <Column
                          field="hasDiscountCode"
                          header="Descuento"
                          sortable
                          body={(rowData: any) => {
                            return (
                              <Tag
                                value={rowData.hasDiscountCode ? 'Sí' : 'No'}
                                severity={
                                  rowData.hasDiscountCode ? 'info' : 'danger'
                                }
                              />
                            );
                          }}
                        ></Column>
                        <Column
                          field="status"
                          header="Actividad"
                          sortable
                          body={statusBody}
                        ></Column>
                        <Column
                          field="specialistUrl"
                          header="Detalle"
                          body={specialistBody}
                        ></Column>
                      </DataTable>
                    </>
                  )}
                  <Paginator
                    first={pagination.first}
                    rows={pagination.rows}
                    totalRecords={pagination.totalDocs}
                    rowsPerPageOptions={
                      pagination.totalDocs > ITEMS_PER_PAGE
                        ? [50, 100, 150, 200, 250]
                        : []
                    }
                    onPageChange={onPageChange}
                    className="paginator"
                  ></Paginator>
                </section>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default UserOutsideScreen;
