import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IAdmin } from '../../../types/AdminInterface';
import { signIn } from '../../../api/adminsApi';
import { saveSession } from '../../../utils/helpers';
import Swal from 'sweetalert2';

const LoginScreen = () => {
  const [user, setUser] = useState<Partial<IAdmin>>({});
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const [showGlobalAlert, setShowGlobalAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar u ocultar la contraseña

  const handleTogglePassword = (field: 'password') => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    }
  };

  const validateFields = () => {
    let isValid = true;

    const emailInput = document.getElementById('email') as HTMLInputElement;
    const passwordInput = document.getElementById(
      'password'
    ) as HTMLInputElement;

    // Validar campo de correo electrónico
    if (!emailInput.value.trim()) {
      setShowEmailAlert(true);
      isValid = false;
    } else {
      setShowEmailAlert(false);
    }

    // Validar campo de contraseña
    if (!passwordInput.value.trim()) {
      setShowPasswordAlert(true);
      isValid = false;
    } else {
      setShowPasswordAlert(false);
    }

    return isValid;
  };

  const handleEmailInput = (event: React.FormEvent<HTMLInputElement>) => {
    setShowEmailAlert(false);
    setUser((prevState) => ({
      ...prevState,
      email: (event.target as HTMLTextAreaElement).value,
    }));
  };

  const handlePasswordInput = (event: React.FormEvent<HTMLInputElement>) => {
    setShowPasswordAlert(false);
    setUser((prevState) => ({
      ...prevState,
      password: (event.target as HTMLTextAreaElement).value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Validar los campos antes de enviar el formulario
    const isValid = validateFields();
    if (isValid) {
      signIn(user)
        .then((response) => {
          saveSession(response);
          Swal.fire({
            icon: 'success',
            title: 'Inicio de sesión exitoso',
            text: 'Has iniciado sesión con éxito.',
            confirmButtonText: 'Ir al dashboard',
          }).then(() => {
            // Redirigir al usuario a /dashboard después de hacer clic en Aceptar en el SweetAlert
            window.location.href = '/dashboard';
          });
        })
        .catch((error) => {
          if (!error.response) {
            setShowGlobalAlert(true);
          }
        });
    }
  };

  return (
    <>
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12 mx-auto">
              <div className="auth-wrapp">
                <figure className="auth-logo">
                  <img src="/assets/images/logo/innata.png" alt="Innata" />
                </figure>
                <article className="auth-article text-center">
                  <h1 className="h3">Super Admin</h1>
                  <p>Iniciar sesión</p>
                </article>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label>Correo electrónico</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="tucorreo@mail.com"
                      id="email"
                      value={user.email}
                      onChange={handleEmailInput}
                    />
                    {showEmailAlert && (
                      <div className="alert alert-danger" role="alert">
                        El correo electrónico es requerido.
                      </div>
                    )}
                  </div>
                  <div className="form-row">
                    <label>Contraseña</label>
                    <div className="password-view">
                      <button
                        type="button"
                        className="password-view__button"
                        onClick={() => handleTogglePassword('password')}
                      >
                        <span className="material-icons-outlined">
                          {showPassword ? 'visibility_off' : 'visibility'}
                        </span>
                      </button>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control mb-4"
                        placeholder="******"
                        id="password"
                        value={user.password}
                        onInput={handlePasswordInput}
                      />
                    </div>
                    {showPasswordAlert && (
                      <div className="alert alert-danger" role="alert">
                        La contraseña es requerida.
                      </div>
                    )}
                    {showGlobalAlert && (
                      <div className="alert alert-danger" role="alert">
                        El usuario y/o contraseña no coinciden.
                      </div>
                    )}
                    <Link to="/recover-password">
                      ¿Olvidaste tu contraseña?
                    </Link>
                  </div>
                  <div className="form-row row--center row--last">
                    <button className="btn btn--type1">Iniciar sesión</button>
                  </div>
                  <div className="form-row row--center row--last">
                    <p>
                      Si aún no tienes una cuenta, puedes crear una{' '}
                      <Link to="/create-account">aquí.</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginScreen;
