import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const NewPasswordScreen = () => {
  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar u ocultar la contraseña
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para mostrar u ocultar la contraseña de confirmación

  useEffect(() => {
    setIsSubmitDisabled(
      password.trim() === '' ||
      confirmPassword.trim() === '' ||
      password.length < 10 ||
      password !== confirmPassword
    );
  }, [password, confirmPassword]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'password') {
      setPassword(value);
      setShowPasswordError(value.length > 0 && value.length < 10);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setShowConfirmPasswordError(value !== password);
    }
  };

  const handleTogglePassword = (field: 'password' | 'confirmPassword') => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Lógica para guardar la nueva contraseña o realizar la acción correspondiente
    // Puedes reemplazar este setTimeout con tu lógica de actualización de contraseña en el backend
    setTimeout(() => {
      Swal.fire({
        icon: 'success',
        title: 'Contraseña actualizada',
        text: 'Tu contraseña ha sido actualizada con éxito.',
        confirmButtonText: 'Aceptar',
      }).then(() => {
        // Redirigir al usuario a /login después de hacer clic en Aceptar en el SweetAlert
        window.location.href = '/';
      });
    }, 500);
  };



  return (
    <>
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12 mx-auto">
              <div className="auth-wrapp">
                <div className="auth-head">
                  <Link to="/" className="btn--simple"><span className="material-icons-outlined icon">arrow_back</span> Regresar</Link>
                </div>
                <figure className="auth-logo">
                  <img src="/assets/images/logo/innata.png" alt="Innata" />
                </figure> 
                <article className="auth-article text-center">
                  <h1 className="h3">Super Admin</h1>
                  <p>Nueva contraseña</p>
                  <p className="gray-text">Ingresa tu nueva contraseña para poder inciar sesión.</p>
                </article>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label>Nueva contraseña</label>
                    <div className="password-view">
                      <button
                        type="button"
                        className="password-view__button"
                        onClick={() => handleTogglePassword('password')}
                      >
                        <span className="material-icons-outlined">
                          {showPassword ? 'visibility_off' : 'visibility'}
                        </span>
                      </button>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="*********"
                        name="password"
                        value={password}
                        onChange={handleInputChange}
                      />
                    </div>
                    {showPasswordError && (
                      <div className="alert alert-danger" role="alert">
                        La contraseña debe tener al menos 10 caracteres.
                      </div>
                    )}
                  </div>
                  <div className="form-row">
                    <label>Validar contraseña</label>
                    <div className="password-view">
                      <button
                        type="button"
                        className="password-view__button"
                        onClick={() => handleTogglePassword('confirmPassword')}
                      >
                        <span className="material-icons-outlined">
                          {showConfirmPassword ? 'visibility_off' : 'visibility'}
                        </span>
                      </button>
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="*********"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleInputChange}
                      />
                    </div>
                   
                    {showConfirmPasswordError && (
                      <div className="alert alert-danger" role="alert">
                        Las contraseñas no coinciden.
                      </div>
                    )}
                  </div>
                  <div className="form-row row--center row--last">
                    <button className="btn btn--type1" disabled={isSubmitDisabled}>
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
};

export default NewPasswordScreen;