import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { IAdmin } from '../../../types/AdminInterface';
import { signUp } from '../../../api/adminsApi';
import { saveSession } from '../../../utils/helpers';

const PASSWORD_MIN_LENGTH = 8;

const SignUpScreen = () => {
  const [user, setUser] = useState<Partial<IAdmin>>({
    username: '',
    email: '',
    password: '',
    invCode: 0,
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'password') {
      setShowPasswordError(value.length < PASSWORD_MIN_LENGTH);
    }

    setIsSubmitDisabled(
      ['username', 'email', 'password', 'invCode'].some(
        (field) => !user[field as keyof IAdmin]
      )
    );
  };

  const handleTogglePassword = (field: 'password') => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (showPasswordError) {
      return;
    }

    return signUp(user)
      .then((response) => {
        saveSession(response);
        Swal.fire({
          icon: 'success',
          title: 'Cuenta creada',
          text: 'Se ha creado tu cuenta con éxito.',
          confirmButtonText: 'Ir al dashboard',
        }).then(() => {
          // Redirigir al usuario a /dashboard después de hacer clic en Aceptar en el SweetAlert
          window.location.href = '/dashboard';
        });
      })
      .catch((error) => {
        if (!error.response) {
          Swal.fire({
            icon: 'error',
            title: 'Error al crear cuenta',
            text: 'Ocurrió un error al crear tu cuenta, por favor intenta de nuevo.',
          });
          return;
        }
        const {
          response: { data },
        } = error;
        Swal.fire({
          icon: 'error',
          title: 'Error al crear cuenta',
          text: data.error,
        });
      });
  };

  return (
    <>
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12 mx-auto">
              <div className="auth-wrapp">
                <div className="auth-head">
                  <Link to="/" className="btn--simple">
                    <span className="material-icons-outlined icon">
                      arrow_back
                    </span>{' '}
                    Regresar
                  </Link>
                </div>
                <figure className="auth-logo">
                  <img src="/assets/images/logo/innata.png" alt="Innata" />
                </figure>
                <article className="auth-article text-center">
                  <h1 className="h3">Super Admin</h1>
                  <p>Crear cuenta</p>
                  <p className="gray-text">
                    Recuerda que para crear tu cuenta debes haber recibido tu{' '}
                    <strong>código de invitación</strong>, si aún no lo tienes,
                    contacta al administrador de <strong>Innata</strong>.
                  </p>
                </article>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tu nombre completo"
                      name="username"
                      value={user.username}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-row">
                    <label>Correo electrónico</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="tucorreo@mail.com"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-row">
                    <label htmlFor="phone">Teléfono</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="0000000000"
                      name="phone"
                      value={user.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-row">
                    <label>Contraseña</label>
                    <div className="password-view">
                      <button
                        type="button"
                        className="password-view__button"
                        onClick={() => handleTogglePassword('password')}
                      >
                        <span className="material-icons-outlined">
                          {showPassword ? 'visibility_off' : 'visibility'}
                        </span>
                      </button>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control mb-4"
                        placeholder="******"
                        name="password"
                        value={user.password}
                        onChange={handleInputChange}
                      />
                    </div>
                    {showPasswordError && (
                      <div className="alert alert-danger" role="alert">
                        La contraseña debe tener al menos{' '}
                        <strong>{PASSWORD_MIN_LENGTH} caracteres</strong>
                      </div>
                    )}
                  </div>
                  <div className="form-row">
                    <label>Código de invitación</label>
                    <input
                      type="number"
                      className="form-control mb-4"
                      placeholder="0000"
                      name="invCode"
                      value={user.invCode}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-row row--center row--last">
                    <button
                      className="btn btn--type1"
                      disabled={!isSubmitDisabled}
                    >
                      Crear cuenta
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpScreen;
