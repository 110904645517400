import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import Modal from 'react-bootstrap/Modal';

interface FormData {
  username: string;
  email: string;
  phone?: string;
}

interface Props {
  onUserCreated: (user: FormData) => Promise<void>;
  onHide: () => void;
  show: boolean;
}

const NewInsideUserComponent: React.FC<Props> = ({
  onUserCreated,
  onHide,
  show,
}) => {
  const [formData, setFormData] = useState<FormData>({
    username: '',
    email: '',
  });

  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    const { username, email } = formData;
    if (username && email) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [formData]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onUserCreated(formData).then(() => {
      onHide(); // cierra el modal después de mostrar la alerta

      // Reinicia los campos del formulario
      setFormData({
        username: '',
        email: '',
      });

      // Desactiva el botón de submit
      setButtonDisabled(true);
    });
  };

  return (
    <>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
        show={show}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir usuario
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="user-form">
            <form onSubmit={handleFormSubmit}>
              <div className="form-row row--left">
                <label>Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre completo del nutriólogo"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-row row--left">
                <label>Correo electrónico</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="miemail@mail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-row row--left">
                <label>Teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="55 5555 5555"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-row row--last row--double">
                <button
                  type="button"
                  className="btn btn--type2"
                  onClick={onHide}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={isButtonDisabled}
                >
                  Crear ususario
                </button>
              </div>
            </form>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewInsideUserComponent;
